.typewriter h3 {
  overflow: hidden;
}

.typewriter h3:nth-child(1) {
  width: 100%;
  animation: type 3s;
}

.typewriter h3:nth-child(2) {
  width: 100%;
  opacity: 0;
  animation: type2 3s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
}

.typewriter h3:nth-child(3) {
  width: 100%;
  opacity: 0;
  animation: type2 3s;
  animation-delay: 1.8s;
  animation-fill-mode: forwards;
}

/* animation keyframes below */

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .responsive-font {
    font-size: 1.2rem;
  }
}
