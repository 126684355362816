body {
  background: #3c5377;
}

.maincontainer {
  perspective: 1000px;
  width: 100%;
  height: 100%;
}

.thecard {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
}

.maincontainer:hover .thecard {
  transform: rotateY(180deg);
}

.thefront,
.theback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em;
}

.thefront {
  background: #f2f3f4;
  color: #000;
  border: 1px solid rgba(108, 145, 194, 0.5); /* Same border style */
}

.theback {
  background: #f2f3f4;
  color: #333;
  transform: rotateY(180deg);
  border: 1px solid rgba(108, 145, 194, 0.5); /* Same border style */
}

/* This block is merely styling for the flip card and is NOT an essential part of the flip code */
.thefront h4,
.theback h4 {
  font-family: "Catamaran", sans-serif;
  padding: 30px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.thefront p,
.theback p {
  font-family: "Catamaran", sans-serif;
  padding: 30px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
}
/* End of non-essential styling block */

/* MoreCards: */

/* BrokerPortal.css */
.paper {
  position: relative;
  width: 100%;
  height: 400px;
  background: #f2f3f4;
  border-radius: 15px;
  box-shadow: 0 25px 60px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.side {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: height 0.5s ease;
}

.side1 {
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.side2 {
  height: 175px;
  background: #081446;
  border-radius: 15px;
  font-weight: 800;
  transition: height 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.paper:hover .side2 {
  height: 100%;
}

.side2 p {
  color: #f2f3f4;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  margin: 0; /* Remove margin to prevent overflow */
}
