

.primary-color {
  color: rgb(1, 151, 221);
}

.primary-bg {
  background-color: rgb(1, 151, 221);
}


.header {
  background-color: white;
}

.welcome-div {
  text-align: left;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: rgb(1, 151, 221);
  cursor: pointer;
}

.nav-button {
  padding-top: 5px; 
  padding-bottom: 5px; 
  padding-left: 30px; 
  padding-right: 30px; 
  color: white;
  border-radius: 5px;
}

.outline-button {
  border: 2px solid rgb(1, 151, 221);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  text-decoration: None;
}

.outline-button:hover {
  background-color: rgb(1, 151, 221);
  color: white;
  cursor: pointer;
}

.welcome-nav-button {
  margin-left: 0px;
  margin-right: auto;
}

.nav-button:hover {
  background-color: rgb(1, 151, 221);
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

#expanded-nav {
  display: block
}

#collapsed-nav {
  display: none;
}


.expert-pic {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.buyer-services {
  padding-left: 100px;
  padding-right: 100px;
  text-align: left;
}

.reactive-shadow {
  box-shadow: 7px 7px 17px rgba(0, 0, 0, 0.7), 
            -10px -10px 20px rgba(255, 255, 255, 0.7);
}

.reactive-shadow:hover {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5), 
            -4px -4px 7px rgba(255, 255, 255, 0.5);
}

.non-reactive-shadow {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5), 
            -4px -4px 7px rgba(255, 255, 255, 0.5);
}


.margin-top-small-screen {
  margin-top: 0px;
}


.shimmer {
  color: grey;
  display:inline-block;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

@media only screen and (max-width: 768px) {
  .VictoryContainer {
      user-select: auto !important;
      pointer-events: auto !important;
      touch-action: auto !important;
  }
}


.error-text {
  color: red;
}


.footer-section {
  padding: 50px;
}


.footer-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.footer-text {
  color: white;
  cursor: pointer;
}

.footer-text:hover {
  color: rgb(1, 151, 221);
}


@media screen and (max-width: 1200px) {
  .header {
    background-color: rgba(0, 0, 0, 0);
  }

  .welcome-div {
    text-align: center;
  }

  .welcome-nav-button {
    margin-left: auto;
    margin-right: auto;
  }

  .nav-link {
    color: white;
  }

  #expanded-nav {
    display: none;
  }

  #collapsed-nav {
    display: block;
  }
  .buyer-services {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  .margin-top-small-screen {
    margin-top: 50px;
  }
  .footer-section {
    padding: 0px;
    padding-top: 30px;
    padding-left: 30px;
  }
  .footer-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}