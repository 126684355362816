* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.logos {
  overflow: hidden;
  padding: 30px 0;
  background: #f2f3f4;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgb(242, 243, 244)
  );
}

.logos:after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(242, 243, 244)
  );
}

.logos-slide {
  display: inline-block;
  white-space: nowrap;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 150px;
  margin: 0 15px;
}

.hoverScale {
  transition: transform 0.2s ease-in-out;
}

.hoverScale:hover {
  transform: scale(1.05);
  cursor: pointer;
}
